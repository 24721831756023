.fadeIn {
  animation: fadeIn 1s ease-in-out;
  animation-fill-mode: forwards;
}

.fadeInUp {
  animation: fadeInUp 1s ease-in-out;
  animation-fill-mode: forwards;
}

.fadeInRight {
  animation: fadeInRight 1s ease-in-out;
  animation-fill-mode: forwards;
}

.fadeInLeft {
  animation: fadeInLeft 1s ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 50px, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(100px, 0, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-100px, 0, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes infiniteStats {
  0% {
    transform: translate3d(-1440px, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
